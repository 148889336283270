import { vModelText as _vModelText, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center bg-gray-900" }
const _hoisted_2 = { class: "mx-4 max-w-4xl justify-center" }
const _hoisted_3 = { class: "justify-start sm:justify-center md:justify-end lg:justify-between xl:justify-around" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HistoryCategoriesPhotoList = _resolveComponent("HistoryCategoriesPhotoList")!
  const _component_PhotoListSkeleton = _resolveComponent("PhotoListSkeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("input", {
          type: "search",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInput) = $event)),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.fetchGame(_ctx.searchInput)), ["enter"])),
          class: "flex-1 text-white px-4 py-2 m-2 bg-gray-800 text-gray-300 rounded-sm",
          placeholder: "Введіть рік..."
        }, null, 544), [
          [_vModelText, _ctx.searchInput]
        ]),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.fetchGame(_ctx.searchInput))),
          class: "py-2 px-5 uppercase font-bold bg-green-400 hover:bg-green-500 rounded-sm ml-2"
        }, " Пошук ")
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("h1", {
        style: {"margin-top":"10px"},
        class: "font-bold text-5xl text-white-50 flex-1 mb-4 justify-center"
      }, " Історія в фото... ", -1)),
      (_ctx.categories)
        ? (_openBlock(), _createBlock(_component_HistoryCategoriesPhotoList, {
            key: 0,
            class: "pr-4 justify-center sm:pr-2",
            categories: _ctx.categories
          }, null, 8, ["categories"]))
        : (!_ctx.categories && !_ctx.error)
          ? (_openBlock(), _createBlock(_component_PhotoListSkeleton, { key: 1 }))
          : _createCommentVNode("", true)
    ])
  ]))
}